import { SITE_NAME } from 'cat-configs/constants';
import siteConfig from 'cat-configs/siteConfig';
import { useEffect } from 'react';

export default function SidebarAdsZone() {
  useEffect(() => {
    !siteConfig[SITE_NAME].offAds && (window.adsbygoogle = window.adsbygoogle || []).push({});
  }, []);

  if (siteConfig[SITE_NAME].offAds) return;
  return (
    <>
      <ins
        className="adsbygoogle"
        style={{ display: 'block' }}
        data-ad-client="ca-pub-1574771834530002"
        data-ad-slot="6414232471"
        data-ad-format="auto"
        data-full-width-responsive="true"
      />
    </>
  );
}
