import { categoriesOutstanding } from 'cat-configs/category';
import { MODAL_SIDEBAR, SITE_NAME } from 'cat-configs/constants';
import siteConfig from 'cat-configs/siteConfig';
import useToast from 'hooks/useToast';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { cloneElement, useCallback, useEffect, useState } from 'react';
import { WithAxiosWrapper } from './withAxios';

const Header = dynamic(() => import('cat-components/layout/Header'), { ssr: false });
const Footer = dynamic(() => import('cat-components/layout/Footer'));

const Sidebar = dynamic(() => import('cat-components/modules/Sidebar'), { ssr: false });
const LoginForm = dynamic(() => import('cat-components/modules/Modal/Login'), { ssr: false });
const RegisterForm = dynamic(() => import('cat-components/modules/Modal/Register'), { ssr: false });
const ForgotPassword = dynamic(() => import('cat-components/modules/Modal/ForgotPassword'), {
  ssr: false,
});

const Layout = ({ children, isReading = false }) => {
  const { query } = useRouter();
  const { ToastContainer, ...toast } = useToast();
  const [visible, setVisible] = useState('');
  const categories = categoriesOutstanding[SITE_NAME]; 
  const { MENU_MOBILE, REGISTER_FORM, LOGIN_FORM, FORGOT_PASSWORD } = MODAL_SIDEBAR;

  const handleOnClose = useCallback(() => {
    setVisible('');
  }, []);
  const onShow = useCallback((key) => {
    setVisible(key);
  }, []);

  useEffect(() => {
    return () => onShow('');
  }, [query, onShow]);

  return (
    <WithAxiosWrapper>
      {!isReading && <Header onShow={onShow} visible={visible} />}
      {cloneElement(children, {
        visible,
        onShow,
        handleonclose: handleOnClose,
        categories: categories,
      })}
      {!isReading && <Footer />}
      <LoginForm visible={visible === LOGIN_FORM} onClose={handleOnClose} onShow={onShow} />
      <RegisterForm visible={visible === REGISTER_FORM} onClose={handleOnClose} onShow={onShow} />
      <ForgotPassword
        visible={visible === FORGOT_PASSWORD}
        onClose={handleOnClose}
        onShow={onShow}
      />
      <Sidebar visible={visible === MENU_MOBILE} onClose={handleOnClose}>
        <span className="sb-uimode" id="sb-toggle-mode">
          <i className="fas fa-moon mr-2" />
          <span className="text-dm">Dark Mode</span>
          <span className="text-lm">Light Mode</span>
        </span>
        <button className="btn toggle-sidebar" onClick={handleOnClose}>
          <i className="fas fa-angle-left" />
        </button>
        <ul className="nav sidebar_menu-list">
          <li className="nav-item">
            <Link passHref href="/">
              <a className="nav-link">Trang chủ</a>
            </Link>
          </li>
          <li className="nav-item">
            <span className="nav-link" title="Types">
              Thể loại
            </span>
            <div className="types-sub">
              {categories.length > 0 &&
                categories.map((category) => (
                  <Link passHref href={`/${siteConfig[SITE_NAME].path.search}?category=${'manga'}`} key={category._id}>
                    <a className="ts-item" href={`/${siteConfig[SITE_NAME].path.search}?category=${'manga'}`}>
                      {category.name}
                    </a>
                  </Link>
                ))}
            </div>
          </li>
          <li className="nav-item">
            <Link passHref href={siteConfig[SITE_NAME].path.search}>
              <a className="nav-link">Tìm kiếm</a>
            </Link>
          </li>
        </ul>
        <div className="clearfix" />
      </Sidebar>
      <ToastContainer />
    </WithAxiosWrapper>
  );
};
export default Layout;
