import useAxios from 'axios-hooks';
import configs from 'cat-api/configs';
import { homeApi } from 'cat-api/homeApi';
import SidebarAdsZone from 'cat-components/Ads/SidebarAdsZone';
import SidebarAdsZoneTop from 'cat-components/Ads/SidebarAdsZoneTop';
import Layout from 'cat-components/layout';
import { COMIC_SLIDER, LABEL_COMICS_SLIDE, SITE_NAME, TABS, URL_WEB } from 'cat-configs/constants';
import config from 'cat-configs/siteConfig';
import { useHistory } from 'cat-hooks/useHistory';
import useTrans from 'hooks/useTrans';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useMemo, useState } from 'react';

const BannerSlider = dynamic(() => import('cat-components/modules/BannerSlider'), { ssr: false });
const ComicsSlider = dynamic(() => import('cat-components/modules/ComicsSlider'), { ssr: false });
const HomeCategories = dynamic(() => import('cat-components/modules/HomeCategories'), {
  ssr: false,
});
const ComicTabs = dynamic(() => import('cat-components/modules/ComicTabs'), { ssr: false });
const HomeComment = dynamic(() => import('cat-components/modules/HomeComment'), { ssr: false });
const MostViewed = dynamic(() => import('cat-components/modules/MostViewed'), { ssr: false });
const ShareSocials = dynamic(() => import('cat-components/modules/ShareSocials'));

export const getStaticProps = async () => {
  const homeResult = await homeApi();

  return {
    props: {
      data: homeResult.data?.data || {},
    },
    revalidate: 30,
  };
};

export default function Home(props) {
  const { data } = props || {};

  return (
    <>
      <Head>
        <title>{config[SITE_NAME].title}</title>
        <meta name="description" content={config[SITE_NAME].description} />
        <meta name="keywords" content={config[SITE_NAME].keywords} />
        <meta property="og:title" content={config[SITE_NAME].title} />
        <meta property="og:site_name" content={config[SITE_NAME].siteName} />
        <meta property="og:description" content={config[SITE_NAME].description} />
        <meta property="og:url" content={URL_WEB + useRouter().asPath} />
        <meta property="og:image" content={config[SITE_NAME].siteThumbnail} />
        <meta property="og:type" content="article" />
      </Head>
      <Layout>
        <HomeTemplate data={data} />
      </Layout>
    </>
  );
}

const HomeTemplate = (props) => {
  const [t] = useTrans();
  const { syncHistory, handleDeteleHistory } = useHistory();
  const { data, categories } = props;
  const { updated_comics, new_comics, top_comics, recommend_comics, sliders, top_comments } = data;
  const [showComment, setShowComment] = useState(false);
  const [currentTabLastedUpdate, setCurrentTabLastedUpdate] = useState(TABS.lastedUpdate.CHAPTER);
  const [currentTabTop, setCurrentTabTop] = useState(TABS.mostViewed.DAY_VIEW);

  const [{ data: dataComicsMostView, loading }] = useAxios(
    {
      url: `/${configs.comicview[currentTabTop]}`,
      params: { top: 10 },
      method: 'GET',
    },
    { useCache: true }
  );

  const tabsComic = useMemo(() => {
    return [
      { label: t('Chapter'), value: TABS.lastedUpdate.CHAPTER },
      { label: t('Volume'), value: TABS.lastedUpdate.VOLUME },
    ];
  }, [t]);

  const tabsTop = useMemo(() => {
    return [
      { value: TABS.mostViewed.DAY_VIEW, label: t('Today') },
      { value: TABS.mostViewed.WEEK_VIEW, label: t('Week') },
      { value: TABS.mostViewed.MONTH_VIEW, label: t('Month') },
    ];
  }, [t]);

  const recommend_comics_mapping = useMemo(() => {
    return recommend_comics?.map((comic) => ({
      ...comic.comicID,
      ...comic,
    }));
  }, [recommend_comics]);

  return (
    <div>
      <BannerSlider sliders={sliders} />
      <div id="wrapper">
        <div id="text-home">
          <div className="container">
            <div className="text-home">
              <div className="text-home-main">{t('Site Description')}</div>
              <div className="social-home-block">
                <div className="shb-icon" />
                <div className="shb-left">
                  <strong>{t('Share Comicaholic')}</strong> {t('to your friends')}
                </div>
                <div className="addthis_inline_share_toolbox">
                  <ShareSocials />
                </div>
                <div className="clearfix" />
              </div>
            </div>
          </div>
        </div>
        <ComicsSlider
          label={t(LABEL_COMICS_SLIDE.top_comics)}
          comics={top_comics}
          flag={COMIC_SLIDER.TRENDING}
        />
        <HomeComment visible={showComment} onToggle={() => setShowComment(!showComment)} topComments={top_comments} />
        <HomeCategories categories={categories} />
        {syncHistory && syncHistory[0] && (
          <ComicsSlider
            label={t(LABEL_COMICS_SLIDE.read)}
            comics={syncHistory || []}
            flag={COMIC_SLIDER.READING}
            onDelete={handleDeteleHistory}
          />
        )}
        <div id="manga-continue" />
        <ComicsSlider
          label={t(LABEL_COMICS_SLIDE.recommend_comics)}
          comics={recommend_comics_mapping}
          flag={COMIC_SLIDER.RECOMENDED}
        />
        <ComicsSlider
          label={t(LABEL_COMICS_SLIDE.new_comics)}
          comics={new_comics}
          flag={COMIC_SLIDER.NEW}
        />
        <div id="main-wrapper">
          <div className="container">
            <div id="mw-2col">
              <div id="main-content">
                <ComicTabs
                  label={t('Latest Updates')}
                  comics={updated_comics}
                  tabs={tabsComic}
                  currentTab={currentTabLastedUpdate}
                  onChange={setCurrentTabLastedUpdate}
                />
                <div className="clearfix" />
              </div>
              <div id="main-sidebar">
                <SidebarAdsZoneTop />
                <MostViewed
                  label={t('Most Viewed')}
                  tabs={tabsTop}
                  data={dataComicsMostView?.data}
                  currentTab={currentTabTop}
                  onChange={setCurrentTabTop}
                  loading={loading}
                />
                <SidebarAdsZone />
              </div>
              <div className="clearfix" />
            </div>
          </div>
          {/* <ComicsSlider
            label={t(LABEL_COMICS_SLIDE.updated_comics)}
            comics={updated_comics}
            flag={COMIC_SLIDER.COMPLETED}
          /> */}
        </div>
      </div>
    </div>
  );
};
