exports.categoriesOutstanding = {
  comicaholic: [
    {
      name: 'Hành động',
      slug: 'hanh-dong',
    },
    {
      name: 'Anime',
      slug: 'anime',
    },
    {
      name: 'Hài Hước',
      slug: 'hai-huoc',
    },
    {
      name: 'Đam Mỹ',
      slug: 'dam-my',
    },
  ],
  mangapaws: [
    {
      name: 'Manhwa',
      slug: 'manhwa',
    },
    {
      name: 'Webtoon',
      slug: 'webtoon',
    },
    {
      name: 'Adaptation',
      slug: 'adaptation',
    },
    {
      name: 'Drama',
      slug: 'drama',
    },
  ],
};
