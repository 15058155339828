module.exports = {
  comicaholic: {
    siteName: 'Comicaholic',
    title: 'Comicaholic - Đọc truyện tranh online',
    description: '✅ Đọc Truyện hay nhất và đầy đủ nhất tại Comicaholic',
    siteLogo: 'https://i.imgur.com/gSe39Kq.png',
    siteThumbnail: 'https://i.imgur.com/gSe39Kq.png',
    keywords: 'Đọc Truyện Tranh Manhwa, Manga, Manhua',
    contact: {
      facebook: 'https://www.facebook.com/comicaholicvn',
      discord: '#',
    },
    views: {
      home: {},
      resetpassword: {
        title: '- Reset password',
      },
      contact: {
        title: '- Liên hệ',
      },
      dmca: {
        title: '- DMCA',
      },
      terms: {
        title: '- Các điều khoản và điều kiện',
      },
      timkiem: {
        title: 'Tìm kiếm truyện tại',
      },
      truyenyeuthich: {
        title: 'Truyện yêu thích tại',
      },
      profile: {
        title: 'Hồ sơ cá nhân - '
      }
    },
    i18n: {
      locales: ['vi'],
      defaultLocale: 'vi',
      localeDetection: true,
    },
    path: {
      comic: 'truyen',
      favoriteComic: 'truyen-yeu-thich',
      search: 'tim-kiem',
      category: 'the-loai',
    },
    fakeSlug: true,
    footer: {
      termofservice: 'Điều khoản và dịch vụ',
      dcma: 'DCMA',
      contact: 'Liên hệ',
      sitemap: 'Sitemap',
      description:
        ' - Đọc Truyện Online Miễn Phí | Truyện Hay. Truyện Mới. Website luôn cập nhập những bộ truyện mới nhất thuộc các thể loại đặc sắc như truyện tiên hiệp, kiếm hiệp, truyện teen, tiểu thuyết ngôn tình hay nhất. Hỗ trợ mọi thiết bị di động và máy tính bảng ...',
      copyright: `© Comicaholic.com`,
    },
    offAds: false,
  },
  mangapaws: {
    siteName: 'Mangapaws',
    title: 'Mangapaws - Đọc truyện tranh online',
    description: '✅ Đọc Truyện hay nhất và đầy đủ nhất tại Mangapaws',
    siteLogo: 'https://i.imgur.com/gSe39Kq.png',
    siteThumbnail: 'https://i.imgur.com/gSe39Kq.png',
    keywords: 'Đọc Truyện Tranh Manhwa, Manga, Manhua',
    contact: {
      facebook: 'https://www.facebook.com/dota2vn',
      discord: '',
    },
    views: {
      home: {},
      resetpassword: {
        title: ' - Reset password',
      },
      contact: {
        title: ' - Liên hệ',
      },
      dmca: {
        title: ' - DMCA',
      },
      terms: {
        title: '- Các điều khoản và điều kiện',
      },
      timkiem: {
        title: 'Tìm kiếm truyện tại',
      },
      truyenyeuthich: {
        title: 'Truyện yêu thích tại',
      },
      profile: {
        title: 'Profile - '
      }
    },
    i18n: {
      locales: ['en'],
      defaultLocale: 'en',
      localeDetection: true,
    },
    path: {
      comic: 'manga',
      favoriteComic: 'favorite-comic',
      search: 'search',
      category: 'manga-genre',
    },
    fakeSlug: true,
    footer: {
      termofservice: 'Terms of service',
      dcma: 'DCMA',
      contact: 'Contact',
      sitemap: 'Sitemap',
      description: ` does not store any files on our server, we only linked to the media which is hosted on 3rd party services.`,
      copyright: `© Mangapaws.me`,
    },
    offAds: true,
  },
};
